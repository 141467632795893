<template>
  <div>
    <v-row
      v-for="version in versions"
      :key="version.title"
    >
      <v-col>
        <v-card flat>
          <v-card-title>
            {{ version.title }}
          </v-card-title>

          <v-card-subtitle v-if="version.subtitle">
            {{ version.subtitle }}
          </v-card-subtitle>

          <v-card-text>
            <div
              v-if="version.summary"
              class="mb-4"
            >
              {{ version.summary }}
            </div>

            <div
              v-if="version.changes"
              class="text-subtitle-1"
            >
              Tilføjelser og ændringer
            </div>

            <ul class="mb-4">
              <li
                v-for="(change, i) in version.changes"
                :key="i"
              >
                {{ change }}.
              </li>
            </ul>

            <div
              v-if="version.fixes"
              class="text-subtitle-1"
            >
              Fejlrettelser
            </div>

            <ul>
              <li
                v-for="(fix, i) in version.fixes"
                :key="i"
              >
                {{ fix }}.
              </li>
            </ul>
          </v-card-text>
        </v-card>

        <v-divider class="mt-4" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Changelog',
  data() {
    return {
      versions: [
        {
          title: 'v1.14.0',
          subtitle: 'August 2024',
          changes: [
            'Optimeret håndtering af brugeradgang til bestemte lokationer',
            'Lokationer uden sager vises nu også i oversigten',
            'Tilføjet systemlog for administratorer'
          ],
          fixes: [
            'Bedre mobilmenu',
            'Forbedret performance på bl.a. lokationer',
            'Mindre fejlrettelser'
          ]
        },
        {
          title: 'v1.13.0',
          subtitle: 'Maj 2024',
          changes: [
            'Nye interne værktøjer der gør behandlingen af kunder mere effektiv'
          ],
          fixes: [
            'Nogle nye brugere kunne i specielle tilfælde opleve ikke at se brugsvilkår og dermed ikke få adgang',
            'For mange emails til følgere på ny leverance',
            'Tidsstempler på PDF af beskeder'
          ]
        },
        {
          title: 'v1.12.0',
          subtitle: 'Oktober 2023',
          changes: [
            'Journalisering af beskeder på leverancer',
            'Introduktion af specifikationer (beta)',
            '"Se kun favoritter" er nu slået fra som udgangspunkt',
            'Optimering af leverandørstyring',
            'Forbedret varesynkronisering'
          ]
        },
        {
          title: 'v1.11.0',
          subtitle: 'Marts 2023',
          changes: [
            'Support, opgavebank, leverancer og lokationer har fået nyt layout og indlæser nu data løbende i stedet for alt på én gang. Det giver bedre ydelse og en bedre oplevelse',
            'For at mindske ventetid på de samme sider, bliver søgning ikke længere udløst automatisk'
          ]
        },
        {
          title: 'v1.10.0',
          subtitle: 'Januar 2023',
          changes: [
            'Varenumre og andre relevante ting kan nu nemt kopieres ved at klikke på dem',
            'Andre interne forbedringer til værktøjer'
          ]
        },
        {
          title: 'v1.9.0',
          subtitle: 'December 2022',
          changes: [
            'Varer kan markeres som favoritter',
            'Vareliste kan filtreres for kun at se favoritter',
            'Vis "Ny pris" i varelisten hvis prisen er ændret indenfor et given periode (lige nu 90 dage)',
            'Forbedrede interne værktøjer til administration'
          ],
          fixes: [
            'Mindre fejlrettelser'
          ]
        },
        {
          title: 'v1.8.0',
          subtitle: 'November 2022',
          changes: [
            'Forbedrede interne værktøjer til administration'
          ],
          fixes: [
            'Mindre fejlrettelser'
          ]
        },
        {
          title: 'v1.7.0',
          subtitle: 'November 2022',
          changes: [
            'Tilføjet internt projekteringsmodul for leverancer',
            'Yderligere forbedring af materialeadministration'
          ]
        },
        {
          title: 'v1.6.0',
          subtitle: 'Oktober 2022',
          changes: [
            'Forbedret materialeadministration og oversigt'
          ]
        },
        {
          title: 'v1.5.1',
          subtitle: 'September 2022',
          changes: [
            'Aktiviteter på afsluttede leverancer vises ikke længere på forsiden',
            'Man kan nu se forskel på åbne og afsluttede sager i lister',
            'Der kan ikke længere tilføjes aftaler på en afsluttet leverance'
          ],
          fixes: [
            'SMS ved ny supportsag viser nu sagsdetaljer korrekt',
            'Andre små fejlrettelser'
          ]
        },
        {
          title: 'v1.5.0',
          subtitle: 'September 2022',
          changes: [
            'Til venstre for dit navn øverst i højre hjørne finder du et ny filter, der gør at du kun ser de sager der har relevans for dig',
            'Sagskort viser hvad der ellers sker på lokationen',
            'Beskeder og sagsforløb er tilføjet til leverancer',
            'Leveranceaftaler der endnu ikke er sendt til godkendelse er gjort mere synlige som kladder',
            'Man kan nu få besked når der oprettes en supportsag med kategori B eller C, samt ved ny besked på en leverance man følger',
            'Behovsdato og forventet antal tekniker-dage er tilføjet Opgavebank',
            'Der kan nu vælges mere end et teknisk område på supportsager',
            'Det er gjort mere tydeligt hvor i processen en sag er',
            'Efter oprettelse af opgave i Opgavebank får man nu en bekræftelse som ved leverancer og support',
            'Når leverance oprettes fra opgavebank benyttes samme dialog som ved oprettelse af selvstændig leverance',
            'Det er nu muligt at sortere supportsager efter seneste aktivitet (en ny fil uploades eller ny besked)',
            'Kun de relevante typer, enheder og tekniske områder for en sag vises for nemmere at kunne overskue informationerne',
            'Man får nu en advarsel hvis man navigerer væk fra en ugemt besked på support- og leverancesager',
            'Vi har fjernet muligheden for at oprette leverance fra en lukket opgave i Opgavebank',
            'Opmærksomhedspunkter skjules fra listevisningen hvis der ikke findes nogen på sagen',
            'Administration af brugeres roller og ansvar er gjort mere brugervenlig',
            'Kontaktlisten er gjort mere overskuelig'
          ],
          fixes: [
            'I nogle tilfælde blev relative datoer beregnet forkert for leveranceaktiviteter pga. forskellige tidszoner',
            'Andre små fejlrettelser'
          ]
        },
        {
          title: 'v1.4.1',
          subtitle: 'December 2021',
          changes: [
            'Ved oprettelse af leverance er valg af tovholder og TPA leveranceansvarlig nu påkrævet',
            'Nyt menupunkt: "Om TPA Portalen" hvor man kan finde tidligere versioner og deres ændringer',
            'På PDF af godkendte leveranceaftaler vises nu også kommentar til godkendelse'
          ]
        },
        {
          title: 'v1.4.0',
          subtitle: 'December 2021',
          changes: [
            'Notifikationer på SMS og e-mail viser nu information om lokation, aftaletype, tildelte roller og navn på den fase en opgave man følger har ændret sig til',
            '"Afdeling" ændret til "Enhed"',
            'Tilføjet info om at få fat i support under FAQ',
            'Sortere kommende leveranceaktiviteter efter dato på forsiden'
          ],
          fixes: [
            'Man blev ofte bedt om at logge ind',
            'Indtastning af for lang tekst i beskrivelse af leveranceaftaler',
            'Man kan ikke længere sætte forventet slut til at være før forventet start (eller omvendt)',
            'Forventet dato i opgavebanken ændrede sig til dags dato ved gem',
            'Link i nogle af notifikationsmails om leverance gik et forkert sted hen',
            'Man ikke kunne downloade materiale på lukkede supportsager',
            'Andre, mindre fejlrettelser'
          ]
        }
      ]
    }
  }
}
</script>