<template>
  <div>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Versionshistorik
          </v-card-title>

          <v-card-text>
            <Changelog />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Changelog from '@/components/Changelog'

export default {
  name: 'About',
  components: {
    Changelog
  }
}
</script>